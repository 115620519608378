import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import Team from "./pages/team";
import TokenInfo from "./pages/tokenInfo";
import Layout from "./components/layout";

function App() {
  return (
    <div className="App">
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/about" element={<About />} exact />
            <Route path="/team" element={<Team />} exact />
            <Route path="/tokenInfo" element={<TokenInfo />} exact />
          </Routes>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
