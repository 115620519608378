import React from "react";

function TokenInfo() {
  return (
    <div className="max-w-[1000px] mx-auto py-20 px-10 lg:px-0">
      <div className="lg:flex-row flex flex-col-reverse items-center lg:space-x-8">
        <div className="w-full mt-12 lg:mt-0">
          <div className="lg:w-full">
            <p className="text-2xl  text-[#7D7D7D]">TOKEN INFO</p>
            <p className="text-[#7D7D7D] text-sm mt-3">
              Each token is assembled from a series of detailed hand-drawn
              assets. There are 6 different categories of attributes, which
              include: skeleton, horns, skull, wings, weapons, and scenes.
              <br />
              <br />
              Your Monokrome is the key to our next step. All MK holders will be
              eligible for Phase 2; a unique twist on NFT breeding allowing
              holders to cherry-pick their assets, stake / earn, and more. Our
              team will not leave you hanging, instead flood our community with
              high-quality utility alongside generations of incredible art.
              <br />
              <br />
              Collecting a Monokrome grants you IP rights to use your NFT in any
              way you wish.
            </p>
          </div>
        </div>
        <img alt="" className="lg:max-w-[50%]" src="./images/00001.jpg" />
      </div>
    </div>
  );
}

export default TokenInfo;
