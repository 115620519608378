import React from "react";

function Team() {
  return (
    <div className="max-w-[1000px] mx-auto py-20 px-10 lg:px-0">
      <div className="lg:flex-row flex flex-col-reverse items-center lg:space-x-8">
        <div className="w-full mt-12 lg:mt-0">
          <div className="lg:w-full">
            <p className="text-2xl  text-[#7D7D7D]">TEAM</p>
            <p className="text-[#7D7D7D] text-sm mt-3">
              With months of careful research and planning our decision was made
              to distance ourselves from the generic ape derivatives & cereal
              box characters to focus on unique art with a twist. From media
              management to blockchain engineering our team is composed of 6
              talented individuals from all around the world working to build a
              large-scale community and a superb future for the Monokromes
              brand.
            </p>
            <div className="text-[#7D7D7D] mt-8">
              <p>Devour - Founder, Head of Marketing</p>

              <p>Invicta - Head Admin</p>
              <p>Typhoon - Back-end Developer</p>

              <p>Matthew - Artist</p>

              <p>Ikigai - Art Management</p>

              <p>Solace - Artist</p>

              <p className="mt-5">
                Looking to collaborate with us? Check out our collab form here:{" "}
                <a
                  target={"_blank"}
                  className="text-blue-600"
                  rel="noreferrer"
                  href="https://forms.gle/CrhswzzfXC3n1LNy7"
                >
                  https://forms.gle/CrhswzzfXC3n1LNy7
                </a>
              </p>
            </div>
          </div>
        </div>
        <img alt="" className="lg:max-w-[50%]" src="./images/00002.gif" />
      </div>
    </div>
  );
}

export default Team;
