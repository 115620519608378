import React, { useEffect, useState } from "react";
import Header from "./header";
// import Lottie from "react-lottie";
// import animationData from "../assets/backgroundImages/flying_bat_loader.json";

function Layout({ children }) {
  const [load, setLoad] = useState(true);

//   const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: animationData,
//   };

  useEffect(() => {
    var imgs = document.images,
      len = imgs.length,
      counter = 0;
    [].forEach.call(imgs, function (img) {
      if (img.complete) incrementCounter();
      else img.addEventListener("load", incrementCounter, false);
    });

    function incrementCounter() {
      counter++;
      if (counter === len) {
        setTimeout(() => {
          setLoad(false);
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    if (load) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
      window.scroll(0, 0);
    }
  }, [load]);

  return (
    <div className="overflow-hidden">
      {load && (
        <div className="min-h-screen bg-brand flex items-center fixed z-[100] justify-center inset-0 w-full bg-black">
          <div className="-mt-60">
            {/* <Lottie
              options={defaultOptions}
              height={300}
              width={200}
              resizeMode="cover"
              className=""
            /> */}
          </div>
          <img
            alt="logo"
            className="max-w-[120px] xl:max-w-[180px] absolute float-animation"
            src="./images/white_mk_png.png"
          />
        </div>
      )}
      <Header />
      {children}
    </div>
  );
}

export default Layout;
