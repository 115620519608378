import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";

function Header() {
  const [menu, setMenu] = useState(false);
  const route = useLocation();
  const routes = [
    {
      title: "HOME",
      url: "/",
    },
    {
      title: "ABOUT",
      url: "/about",
    },
    {
      title: "TOKEN INFO",
      url: "/tokenInfo",
    },
    {
      title: "TEAM",
      url: "/team",
    },
  ];
  return (
    <div className="w-full relative z-50 flex items-center justify-between p-4">
      <div className="relative w-full max-w-[110px]">
        <button onClick={() => setMenu(!menu)} className="max-w-[36px]">
          <img alt="" src="./images/arrow.png" />
        </button>
        <OutsideClickHandler
          onOutsideClick={() => {
            setMenu(false);
          }}
        >
          <div
            className={`${
              menu ? "block" : "hidden"
            } absolute bg-[#191919]   w-full flex flex-col z-[100]   -bottom-36`}
          >
            {routes.map((item, idx) => {
              return (
                <Link
                  className={`${
                    routes.length - 1 !== idx && "border-b border-gray-800 "
                  } ${
                    route.pathname === item.url ? "bg-gray-800" : ""
                  }  px-2 text-sm text-white py-2 hover:bg-gray-800 transition-all duration-200 ease-in-out`}
                  to={item.url}
                  key={idx}
                  onClick={() => {
                    setMenu(false)
                  }}
                >
                  {item.title}
                </Link>
              );
            })}
          </div>
        </OutsideClickHandler>
      </div>

      <div className="flex items-center">
        <a
          target={"_blank"}
          rel="noreferrer"
          href="https://discord.com/invite/monokromes"
          className="max-w-[42px] hover:scale-105 transition-all duration-300 ease-in-out transform"
        >
          <img alt="" src="./images/discord.png" />
        </a>
        <a
          target={"_blank"}
          rel="noreferrer"
          href="https://twitter.com/monokromes"
          className="max-w-[36px] hover:scale-105 transition-all duration-300 ease-in-out transform"
        >
          <img alt="" src="./images/twitter.png" />
        </a>
      </div>
    </div>
  );
}

export default Header;
