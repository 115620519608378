import React from "react";

function Home() {
  return (
    <div className=" min-h-screen bg-cover   pb-10">
      <div className="flex items-center justify-center w-full flex-col">
        <img
          alt="logo"
          className="absolute z-10 top-0 sm:top-[-30%] md:top-[-8%] lg:top-[-24%] lg:h-full px-5"
          src="./images/logoTop.png"
        />
      </div>
      {/* <img
        alt=""
        src="./images/backgroundMain.png"
        className="absolute inset-0  w-full md:block hidden  h-full xl:h-screen z-0 object-cover object-center"
      /> */}

      <div className="mx-auto max-w-[700px] lg:max-w-[620px] px-10 lg:px-0 flex flex-col items-center justify-center relative z-10 mt-[30%] sm:mt-40 md:mt-[38%] lg:mt-[16%] xl:mt-40 2xl:mt-[20%]">
        <img alt="" src="./images/process.png" className="max-w-[200px]" />
        <div className="md:flex -mt-14 md:space-x-6">
          <img
            alt=""
            className="w-full md:max-w-[300px] object-cover object-center"
            src="./images/1.gif"
          />
          <div className="bg-[#191919] text-white text-[12px] px-0.5 py-1 max-w-[] leading-[20px]">
            The theme and color pallet of Monokromes is based on chiaroscuro
            art. It is the use of contrasting light and dark colors to make a
            unique, but monotone composition.
            <p className="mt-4">
              Each token is assembled from a series of detailed hand-drawn
              assets. There are 6 different categories of attributes, which
              include: skeleton, horns, skull, wings, weapons, and scenes,
            </p>
            <p className="mt-4">
              Your Monokrome is evolvable, meaning you will be able to upgrade
              your art. Once your token is upgraded the Monokrome will become
              increasingly stronger, and the art will reflect its evolution.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
