import React from "react";

function About() {
  return (
    <div className="max-w-[1000px] mx-auto py-20 px-10 lg:px-0">
      <div className="lg:flex-row flex flex-col-reverse items-center lg:space-x-8">
        <div className="w-full mt-12 lg:mt-0">
          <div className="lg:w-full">
            <p className="text-2xl  text-[#7D7D7D]">Lore</p>
            <p className="text-[#7D7D7D] text-sm mt-3">
              It had been tens of thousands of years since their passing. The
              Monokrome curse was reborn… 3,000 dark entities put their bones
              back together and dug themselves out of the ground to conquer what
              was left of the Solana universe. Day by day, each Monokrome seems
              to grow stronger, evolving into more advanced versions of their
              predecessors. It's only time until the [redacted] show up...
            </p>
          </div>
          <div className="w-full mt-8 lg:mt-14">
            <p className="text-2xl  text-[#7D7D7D]">Art</p>
            <p className="text-[#7D7D7D] text-sm mt-3">
              Monokromes are unique, detailed and very artistically pleasing.
              The theme and color pallet of Monokromes is known as something
              called chiaroscuro art. It is the use of contrasting light and
              dark colors to make a composition.
              <br />
              The next generation of Monokromes will use a new, different yet
              equally as unique style to the NFT world. We will change the way
              you look at generative art, and most importantly, utility.
            </p>
          </div>
        </div>
        <img alt="" className="lg:max-w-[50%]" src="./images/mkgif.gif" />
      </div>
    </div>
  );
}

export default About;
